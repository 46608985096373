import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NewsSection.css';

const NewsSection = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [error, setError] = useState('');
  const apiPath = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(`${apiPath}/api1/newsDataGet`);
        const data = await response.json();

        if (data.IsError) {
          setError(data.ErrorMessage);
        } else {
          let newsData = [];

          if (data.NewsDataJson) {
            newsData = JSON.parse(data.NewsDataJson);
          }

          setNewsItems(newsData.map((entry) => ({
            imageName: entry.ImageName || '-',
            title: entry.Title || '-',
            headline: entry.Headline || '-',
            link: entry.Link || '-',
          })));
        }
      } catch (err) {
        setError('An error occurred while fetching data');
      }
    };

    fetchNews();
  }, []);

  // // Function to truncate text
  // const truncateText = (text, length = 100) => {
  //   if (text.length > length) {
  //     return text.slice(0, length) + '...';
  //   }
  //   return text;
  // };

  return (
    <div id="news" className="news-section">
      <h2>What's New</h2>
      {error && <p className="error">{error}</p>}
      <div className="news-container">
        {newsItems.map((item, index) => (
          <div key={index} className="news-item">
            <img            
              src={process.env.PUBLIC_URL + `/newsImages/${item.imageName}`}
              alt={item.title}
              className="news-image"
            />

            <h3>{item.title}</h3>

            {/* <p>{truncateText(item.headline)}</p> */}
            <p>{item.headline}</p>

            {/* Arrow inside a circle */}
            <Link to={`/${item.link}`} className="arrow-container">
              <div className="arrow-circle">→</div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsSection;
