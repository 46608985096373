import './Terms.css';

const Terms = () => {

  return (
    <div className="terms-main">
      <div className="terms">
        <h1>Terms</h1>
          When you play or register in Galactic Odyssey, you are acknowledging that you have read and accept the terms and conditions outlined on our site.
          Users who do not comply with this agreement, may have the accounts blocked, deleted or suffer other type of game penalty which the team considers appropriate.

          <ul>
              <li>The use of one account by different users (account-sharing) is prohibited.</li><br/>
              <li>User is solely responsible to have a strong and complex password and to keep it private for safety purposes. We will not take responsibility for the consequences of anyone accessing the user's account.</li><br/>
              <li>The use of shared IP are limited to upgraded accounts and additional shared IP rules applies to interactions between accounts using the same IP. A shared IP consists of the same IP address being used by more than one user on the same game server. Galactic Odyssey reserves the right to refuse permission for the use of shared IPs.</li><br/>
              <li>The use of open proxies or any software with the purpose of avoiding our IP detection system, is prohibited.</li><br/>
              <li>Posting in any form, illegal, pornographic, sexual, nude or semi-nude, xenophobic, religious, offensive, commercial or otherwise wrongful content is prohibited.</li><br/>
              <li>It is forbidden to impersonate other AE players or guilds (having the same nickname/tag in a different game server is not considered a violation of this rule).</li><br/>
              <li>It is forbidden to impersonate Galactic Odyssey staff, or use a name from Galactic Odyssey staff or the name Galactic Odyssey itself as nickname or other.</li><br/>
              <li>Displaying base coordinates or references to other games on your profile or guild profile is not allowed.</li><br/>
              <li>It is prohibited to load any sites of Galactic Odyssey with any other programs than web browsers. As is forbidden to use tools that simulate or change the web interface. The same applies to scripts that load or read site pages (making actions or collecting data). Is prohibited to use auto-refresh functions, tools to open or refresh several pages simultaneously and other browser integrated mechanisms of this nature.</li><br/>
              <li>Game accounts are personal and nontransferable. If the control of an account is transferred to another person, no disciplinary action will be taken, but Galactic Odyssey may not recognize it as a different owner. Announcing the interest in the sale or purchase of a game account using any means of communication provided by Galactic Odyssey is strictly forbidden.</li><br/>
              <li>Disrespecting the game staff may incur in an account suspension or ban. Users must respect the judgment of admins and game operators, any complaint must be presented in a respectful way by email.</li><br/>
              <li>Users must can't interfere with the ability of other Galactic Odyssey members to enjoy playing the game in accordance with its rules, or that increases our expense or difficulty in maintaining the service.</li><br/>
              <li>Any exploitation of a weakness or a bug of the game or in one of our systems is forbidden. Sharing these exploits with other players is also forbidden. Users are required to report bugs or exploits to Galactic Odyssey staff either on Discord or via email: vmgamingltd@gmail.com.</li><br/>
              <li>Any attempt to access our servers, protected or coded data that is not presented to open source or any sort of hacking, is forbidden.</li><br/>         
              <li>We encourage users to promote Galactic Odyssey. However, employing any of the following methods of promotion is forbidden: spam, bulk mail, bulk forum postings, pop-up ads, pop-under ads, and unsolicited instant messages.</li><br/>
              <li>Galactic Odyssey team reserves the right to modify the terms and conditions of this site at any time.</li><br/>
              <li>We cannot be held responsible for unforeseen or uncontrollable events involving member participation, technical issues, problems with our reserve fund, or legislation. Events of this nature may occur in the necessity of closing the site.</li><br/>
              <li>We are not liable in any way for server breakdown, faulty programming etc. The occurrence of any disadvantages for a user resulting from server breakdowns, faulty programming etc. does not entitle the user to claim the recovery of his account's status before the occurrence.</li><br/>
              <li>Galactic Odyssey only ensures game support in English. The offering of such support in any other language at any given time is an act of courtesy and can be terminated at any time by Galactic Odyssey team.</li><br/>
              <li>We are not liable for any damages caused by the use of Galactic Odyssey.</li><br/>
              <li>We remind users that excessive participation in computer games involves the risk of serious personal injury.</li><br/>
              <li>Galactic Odyssey does not assume any responsibility for external links content. The authors of those external web pages are the responsible for those contents.</li><br/>
              <li>The Terms of Use were originally written in English, therefore the terms written in any other language results from mere translations. In case of divergence between the two, the Terms of Use written in English prevail.</li><br/>
          </ul>
      </div>
    </div>
  );
};

export default Terms;
