import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './NewsDetails.css';

const NewsDetails = () => {
  const { link } = useParams(); // Get the `link` parameter from the URL
  const [newsDetails, setNewsDetails] = useState(null);
  const apiPath = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Fetch the details for this news item based on the `link` parameter
    const fetchNewsDetails = async () => {
      try 
      {
        const response = await fetch(`${apiPath}/api1/newsDataGet`);
        const data = await response.json();
        let newsData = [];

        if (data.NewsDataJson) {
          newsData = JSON.parse(data.NewsDataJson);
        }

        const selectedNews = newsData.find((entry) => entry.Link === link);
        setNewsDetails(selectedNews);
      } 
      catch (error) 
      {
        console.error('Error fetching news details:', error);
      }
    };

    fetchNewsDetails();
  }, [link]);

  if (!newsDetails) return <p>Loading...</p>;

  return (
    <div className="news-details">
      <h1>{newsDetails.Title}</h1>
      <div dangerouslySetInnerHTML={{ __html: newsDetails.Details }} />
    </div>
  );
};

export default NewsDetails;
