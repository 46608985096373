import './Terms.css';

const Privacy = () => {

  return (
    <div className="terms-main">
    <div className="terms">
      <h1>Privacy policy</h1>
        As the owner of the website (The "Site"), VM Gaming s.r.o. ("we" or "us") understand that your privacy is of critical importance.<br/>
        This Privacy Policy tells you about our policies regarding the collecting, storage and use of personal data.

        <ul>
            <li>We collect personal information when you register our services or otherwise voluntarily provide such information. The personal information we collect includes: your name, email address, login credentials, country and content of communications you send to us.</li><br/>
            <li>Our sites uses browser cookies to enable the efficient working and improve the quality of our services.</li><br/>
            <li>The personal data we collect from you are used to provide you our services, to communicate with you, monitor our services and any exploit/hack attempts.</li><br/>
            <li>We don't advise to disable or selectively turn off our cookies in your browser settings, or by managing preferences in your browser. This can affect how you are able to interact with our site and will probably include the inability to login to the Galactic Odyssey account.</li><br/>
            <li>If you reside in a country in the European Economic Area (EEA), then under the GDPR and applicable data protection laws you have the rigts, among other things, to access your personal data, have us erase it, and/or restrict its further processing. If you wish to access or delete your personal data (in any) maintained by us, please contact us at vmgamingltd@gmail.com.</li><br/>
            <li>Personal Information we collect from you are currently stored securely on servers in Estonia through a third party web hosting service provider. We take the information security very seriously and we make sure to use industry recognized hosting providers.</li><br/>
            <li>We keep your personal data for the period necessary to provide the services and compliance with legal obligations.</li><br/>
            <li>We will always cooperate with law enforcement agencies and authorities in any investigations concerning fraud or any type of Internet crime.</li><br/>
            <li>We may change this Privacy Policy from time to time. You can periodically check this Privacy Policy to stay informed. By using our services, you automatically provide a consent to comply with our Privacy Policy and Terms.</li><br/>
        </ul>
    </div>
    </div>
  );
};

export default Privacy;
