import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import './NewsSection.css';
import NewsSection from './NewsSection';
import NewsDetails from './NewsDetails';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
  const location = useLocation();

  const images = [
    process.env.PUBLIC_URL + '/Header1.png',
    process.env.PUBLIC_URL + '/Header2.png',
    process.env.PUBLIC_URL + '/Header3.png',
    process.env.PUBLIC_URL + '/Header4.png',
    process.env.PUBLIC_URL + '/Header5.png',
    process.env.PUBLIC_URL + '/Header6.png',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const domainName = process.env.REACT_APP_DOMAIN_NAME;
  const domainPrefix = process.env.REACT_APP_DOMAIN_NAME_PREFIX;

  const baseURL = `${domainPrefix ? `${domainPrefix}.` : ''}${domainName}`;
  
  return (
    <div className="App">
      
      {location.pathname !== "/terms" && location.pathname !== "/privacy" && (
        <>
          <header className="App-header">
            <div>
              <a href={`${baseURL}`} title="Galactic Odyssey">
                <img src="GAOlogo2.png" alt="Galactic Odyssey Logo" className="App-logo"/>
              </a>
            </div>

            <nav>
              <ul className="nav-menu">
                <li><a href="/wiki">Wiki / FAQ</a></li>
                <li><a href="https://test.galacticodyssey.space/gao" className="green-button">Play</a></li>
                <li><a href="/leaderboards" className="yellow-button">Leaderboards</a></li>
              </ul>
            </nav>

            <div className="App-header-genre">
              Idle incremental adventure strategy browser game
            </div>
          </header>
          
          <div id="main-slider" className="slider">
            <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className="slider-image" />
            <button className="slider-button prev" onClick={prevImage}>❮</button>
            <button className="slider-button next" onClick={nextImage}>❯</button>
          </div>
        </>
      )}

      <Routes>
        <Route path="/" element={<NewsSection />} />
        <Route path="/:link" element={<NewsDetails />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>

      <div className="App-header-bottom">
        VM Gaming s.r.o. © 2024-2025
        <div>         
          <a href="/terms" title="Terms">Terms</a>
          |
          <a href="/privacy" title="Privacy policy">Privacy policy</a>
          |
          Contact: vmgamingltd@gmail.com
        </div>
      </div>
    </div>
  );
}

export default App;
